import { Box, Grid } from '@mui/material';
import { sendEcommerce } from '@aph/components/gtm/useGtm';
import { ImageLink } from '~/contentful/components/image-link/image-link';
import type { IImageLink } from '~/contentful/types';

interface BannerCardProps {
  banner: IImageLink | undefined;
}

const getTrackingData = (banner: Pick<IImageLink, 'fields' | 'sys'>) => ({
  creative_name: banner.fields.title,
  creative_slot: 'jm_banner',
  promotion_id: banner.sys.id,
  promotion_name: 'category page jm banner',
});

export const BannerCard = ({ banner }: BannerCardProps) => {
  const sendViewPromotion = () => {
    if (banner) {
      sendEcommerce({
        event: 'view_promotion',
        ecommerce: getTrackingData(banner),
      });
    }
  };

  const sendSelectPromotion = () => {
    if (banner) {
      sendEcommerce({
        event: 'select_promotion',
        ecommerce: getTrackingData(banner),
      });
    }
  };

  return (
    <Grid
      item
      gridColumn="span 2"
      height="100%"
      width="100%"
      order={{ xs: 3, sm: 2, lg: 3, xl: 5 }}
    >
      <Box position="relative" width="100%" height="100%">
        <ImageLink
          alt={banner?.fields?.image?.fields.description ?? ''}
          height={banner?.fields?.image?.fields.file.details.image?.height}
          width={banner?.fields?.image?.fields.file.details.image?.width}
          src={banner?.fields?.image?.fields.file.url ?? ''}
          href={banner?.fields?.link}
          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
          onInView={sendViewPromotion}
          onClick={sendSelectPromotion}
        />
      </Box>
    </Grid>
  );
};

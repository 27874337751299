import React from 'react';
import { Box, Grid } from '@mui/material';
import type { IImageLink } from '~/contentful/types';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { ArticleCard, type ArticleCardProps } from './article-card/article-card';
import { ArticleCardSkeleton } from './article-card/article-card-skeleton';
import { BannerCard } from './banner-card/banner-card.component';

export type ArticleGridProps = {
  listName?: ArticleCardProps['listName'];
  articles?: ReadonlyArray<ArticleCardProps['article']>;
  isLoadingNext?: boolean;
  isLoadingPrevious?: boolean;
  totalCount?: number;
  isFetching?: boolean;
  banner?: IImageLink | undefined;
};

export const ArticleGrid = ({
  listName,
  articles = [],
  isLoadingNext = false,
  isLoadingPrevious = false,
  isFetching = false,
  totalCount = 0,
  banner,
}: ArticleGridProps) => {
  const amountToLoad = Math.min(
    (totalCount === 0 ? DEFAULT_PAGE_SIZE : totalCount) - articles.length,
    DEFAULT_PAGE_SIZE,
  );
  const renderLoader = () =>
    [...Array(amountToLoad).keys()].map((i) => <ArticleCardSkeleton key={i} />);

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(2, minmax(0, 1fr))',
        sm: 'repeat(3, minmax(0, 1fr))',
        lg: 'repeat(4, minmax(0, 1fr))',
        xl: 'repeat(6, minmax(0, 1fr))',
      }}
      columnGap={{ xs: 1, sm: 2 }}
      rowGap={{ xs: 1, sm: 2 }}
      sx={[
        isFetching
          ? {
              filter: 'blur(2px)',
            }
          : null,
      ]}
    >
      {isLoadingPrevious && renderLoader()}
      {banner && <BannerCard banner={banner} />}
      {articles.map((article, index) => {
        if (listName) {
          return (
            <Grid item order={index + 1} key={article.articleCode}>
              <ArticleCard article={article} listName={listName} index={index} />
            </Grid>
          );
        }
        if (process.env.NODE_ENV === 'development') {
          throw Error('List name must be implemented for tracking purposes');
        }
        return null;
      })}
      {isLoadingNext && renderLoader()}
    </Box>
  );
};

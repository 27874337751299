import { Typography } from '@aph/ui/components/typography/typography';

export const NewsLabel = () => (
  <div className="flex">
    <Typography
      typography="body"
      color="text-inverse"
      className="bg-action rounded-bl-lg rounded-br-2xl rounded-tl-2xl rounded-tr-lg px-2 py-1 font-bold"
    >
      Nyhet
    </Typography>
  </div>
);

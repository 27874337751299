import NextLink from 'next/link';
import { Box, Link as MuiLink, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AddArticleToCartComponent } from '@aph/components/product-variant/add-article-to-cart-button/add-article-to-cart.component';
import { useIsBot } from '@aph/hooks/use-is-bot';
import { Typography } from '@aph/ui/components/typography/typography';
import { formatProductUrl } from '@aph/utilities/format-product-url';
import type { IArticle } from '../../../generated/ArticlesClient';
import { useArticleCardTracking } from '../../../hooks/use-article-card-tracking/use-article-card-tracking';
import { ArticleImage } from '../../article-image/article-image.component';
import { ArticleLabels } from '../../article-labels/article-labels';
import { ArticlePrice } from '../../article-price/article-price';
import { ArticleProductType } from '../../article-product-type/article-product-type';

export type ArticleCardProps = {
  article: IArticle;
  index: number;
  listName: string;
};

export const ArticleCard = ({ article, listName, index }: ArticleCardProps) => {
  const { sendSelectItemEvent, cardRef } = useArticleCardTracking(article, listName, index);
  const isBot = useIsBot();

  return (
    <Stack
      ref={cardRef}
      height="100%"
      data-testid="ArticleCard"
      padding={(theme) => theme.spacing(2, 2, 0, 2)}
      borderRadius={{ xs: 4, sm: 6 }}
      bgcolor={(theme) => theme.palette['color/background/elevated']}
      sx={{
        '&:hover': {
          boxShadow: '0px 0px 10px 0px #3031330D',
        },
      }}
    >
      <NextLink
        data-testid="ArticleCardLink"
        href={{
          pathname: formatProductUrl(article.articleUrlSegment),
          // we don't want to use trackingId for bots (SEO)
          query: article.trackingId && !isBot ? { ri: article.trackingId } : undefined,
        }}
        passHref
        legacyBehavior
        prefetch={false}
      >
        <MuiLink underline="none" flexGrow={1} onClick={() => sendSelectItemEvent()}>
          <Stack data-testid="ArticleCardContent" height="100%">
            <Box position="relative">
              <ArticleImage
                src={article.images?.[0]}
                alt={article.name ?? ''}
                layout="constrained"
                objectFit="contain"
                width={300}
                height={300}
                shouldUseRxFallback={article.requiresPrescriptionForPurchase}
              />
              <Box position="absolute" top={0} width="100%">
                <ArticleLabels
                  certifications={article.certifications}
                  isNew={article.isNew}
                  price={article.price}
                  tags={article.tags}
                />
              </Box>
            </Box>
            <Box height={(theme) => theme.spacing(3)} alignItems="center">
              <ArticleProductType productType={article.productType} />
            </Box>
            <Typography
              typography="body"
              className="mb-1 line-clamp-2 flex-1 text-ellipsis whitespace-normal font-bold"
            >
              {article.name}
            </Typography>
            <ArticlePrice price={article.price} showRetailPrice={false} spaceBetween />
          </Stack>
        </MuiLink>
      </NextLink>
      <AddArticleToCartComponent article={article} size="medium" listName={listName} />
      <Box
        height={(theme) => theme.spacing(3.5)}
        display="flex"
        justifyContent="center"
        paddingTop={0.5}
      >
        {article.isWebSaleOnly && (
          <Typography
            data-testid="ArticleWebSalesOnlyInformation"
            typography="footnote"
            color="text-subtle"
          >
            <FormattedMessage id="COMMON.PRODUCT_CARD.IS_WEB_SALE_ONLY" />
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

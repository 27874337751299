import clsx from 'clsx';
import { type IArticle } from '~/articles/generated/ArticlesClient';
import { NewsLabel } from './news-label';
import { PangPrisLabel } from './pangpris-label';
import { PrisVartLabel } from './prisvart-label';
import { PromotionLabel } from './promotion-label';
import { RosaBandetLabel } from './rosabandet-label';
import { ValvaldLabel } from './valvald-label';

type TagLabelProps = {
  absolutePositionTopLabels?: boolean;
} & Pick<IArticle, 'price' | 'tags'>;

export const TagLabels = ({ tags, absolutePositionTopLabels, price }: TagLabelProps) => {
  const hasTags = tags && tags.length > 0;
  if (!price?.salesInformation && !hasTags) {
    return null;
  }
  const packaging = tags?.find((tag) => tag.type === 'Packaging');
  const certifications = tags?.find((tag) => tag.type === 'Certification');

  const isPrisVart = packaging && packaging.value === 'PrisVart';
  const isRosaBandet = packaging && packaging.value === 'RosaBandet';
  const isPangPris = packaging && packaging.value === 'PangPris';
  const isNyhet = packaging && packaging.value === 'Nyhet';

  const isValVald = certifications && certifications.value === 'Valvald';

  return (
    <>
      <div data-testid="ArticleLabels" className="flex">
        <div className="flex flex-col gap-1">
          <div
            className={clsx('flex flex-col gap-1', {
              'absolute top-0 z-10': absolutePositionTopLabels,
            })}
          >
            {isNyhet && <NewsLabel />}
            {price?.salesInformation && (
              <PromotionLabel
                promotionTarget={price.salesInformation.promotionTarget}
                promotionText={price.salesInformation.flyerInformation?.text}
              />
            )}
          </div>
          <div className="flex">
            {isRosaBandet && <RosaBandetLabel />}
            {isValVald && !isRosaBandet && <ValvaldLabel />}
          </div>
        </div>
      </div>

      <div
        className={clsx({
          'absolute right-0 top-0 z-10': absolutePositionTopLabels,
        })}
      >
        {isPangPris && <PangPrisLabel />}
        {isPrisVart && <PrisVartLabel />}
      </div>
    </>
  );
};

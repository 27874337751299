import { Stack, SvgIcon } from '@mui/material';
import Club from '@aph/icons/klubbhjartat.svg';
import { Typography } from '@aph/ui/components/typography/typography';
import type { PromotionFlyerInformation, PromotionTarget } from '../../generated/ArticlesClient';

interface PromotionLabelProps {
  promotionTarget?: PromotionTarget;
  promotionText?: PromotionFlyerInformation['text'];
}

export const PromotionLabel = ({ promotionText, promotionTarget }: PromotionLabelProps) => {
  const isClubMember = promotionTarget === 'ClubMember';

  if (!promotionText && !isClubMember) {
    return null;
  }

  return (
    <Stack
      direction="row"
      gap={1}
      padding={(theme) => theme.spacing(0.7, 1.8)}
      borderRadius={100}
      maxWidth="100%"
      width="fit-content"
      bgcolor={(theme) =>
        isClubMember
          ? theme.palette['color/background/visual']
          : theme.palette['color/background/notice']
      }
    >
      {isClubMember && (
        <SvgIcon
          inheritViewBox
          fontSize="small"
          sx={{
            minWidth: 70,
          }}
        >
          <Club />
        </SvgIcon>
      )}
      {promotionText && (
        <Typography
          typography="body"
          className="whitespace-nowrap font-bold"
          color={isClubMember ? 'text-brand' : 'text-notice'}
        >
          {promotionText}
        </Typography>
      )}
    </Stack>
  );
};

import { Box } from '@mui/material';
import { Image } from '@aph/components/common';

export const ValvaldLabel = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width={32}
    height={32}
    bgcolor={(theme) => theme.palette['color/background/elevated']}
    borderRadius="100%"
    border={(theme) => `1px solid ${theme.palette['color/border/subtle']}`}
  >
    <Image
      src="/_next-public/static/images/valvald.png"
      alt="välvald logo"
      width={24}
      height={(24 * 109) / 145}
      unoptimized
    />
  </Box>
);

import clsx from 'clsx';
import { FeatureToggleComponent, Off, On } from '@aph/components/common';
import { type IArticle, PromotionFlyerStyle } from '../../generated/ArticlesClient';
import { NewsLabel } from './news-label';
import { PromotionLabel } from './promotion-label';
import { RosaBandetLabel } from './rosabandet-label';
import { TagLabels } from './tag-labels';
import { ValvaldLabel } from './valvald-label';

export type ArticleLabelsProps = {
  absolutePositionTopLabels?: boolean;
} & Pick<IArticle, 'isNew' | 'price' | 'certifications' | 'tags'>;

export const ArticleLabels = ({
  isNew,
  price,
  certifications,
  absolutePositionTopLabels,
  tags,
}: ArticleLabelsProps) => {
  if (!price?.salesInformation && !isNew && !certifications) {
    return null;
  }

  const isRosaBandet =
    price?.salesInformation?.flyerInformation?.style === PromotionFlyerStyle.RosaBandet;
  const isValVald = certifications?.includes('Välvald');

  return (
    <div className="flex justify-between">
      <FeatureToggleComponent featureName="aph_features_new_price_communication">
        <On>
          <TagLabels
            tags={tags}
            absolutePositionTopLabels={absolutePositionTopLabels}
            price={price}
          />
        </On>
        <Off>
          <div data-testid="ArticleLabels" className="flex">
            <div className="flex flex-col gap-1">
              <div
                className={clsx('flex flex-col gap-1', {
                  'absolute top-0 z-10': absolutePositionTopLabels,
                })}
              >
                {isNew && <NewsLabel />}
                {price?.salesInformation && (
                  <PromotionLabel
                    promotionTarget={price.salesInformation.promotionTarget}
                    promotionText={price.salesInformation.flyerInformation?.text}
                  />
                )}
              </div>
              <div className="flex">
                {isRosaBandet && <RosaBandetLabel />}
                {isValVald && !isRosaBandet && <ValvaldLabel />}
              </div>
            </div>
          </div>
        </Off>
      </FeatureToggleComponent>
    </div>
  );
};

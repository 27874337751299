import PangPris from '@aph/images/pangpris.svg';

const pangPrisTextClasses =
  'text-[13px] leading-[10px] sm:text-base sm:leading-[14px] xl:text-[13px] xl:leading-[10px] font-black font-ica-heading text-[#FCE2DB]';

export const PangPrisLabel = () => (
  <div className="relative">
    <PangPris className="h-[50px] w-[50px] sm:h-[70px] sm:w-[70px] xl:h-[50px] xl:w-[50px]" />
    <div className="-mt-0.25 absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-center">
      <p className={pangPrisTextClasses}>Pang</p>
      <p className={pangPrisTextClasses}>pris</p>
    </div>
  </div>
);

import { Image } from '@aph/components/common';

export const RosaBandetLabel = () => (
  <Image
    src="/_next-public/static/images/RosaBandet_Emblem_Rund_2023_RGB.png"
    alt="rosabandet 2023 logo"
    width={100}
    height={100}
  />
);

import { Typography } from '@aph/ui/components/typography/typography';

export const PriceWithTitle = ({
  title,
  price,
  strikeThrough = false,
  isDiscounted = false,
}: {
  title: string;
  price: string;
  strikeThrough?: boolean;
  isDiscounted?: boolean;
}) => {
  return (
    <div data-testid="PriceWithTitle" className="flex flex-col">
      <Typography typography="footnote" color="text-subtle">
        {title}
      </Typography>
      <Typography
        typography="priceMedium"
        color={isDiscounted ? 'text-notice' : 'text-default'}
        className={strikeThrough ? 'line-through' : ''}
        asChild
      >
        <h2>{price}</h2>
      </Typography>
    </div>
  );
};

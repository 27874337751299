import PrisVart from '@aph/images/prisvart.svg';

const prisVartTextClasses = 'text-[11px] leading-s font-bold font-ica-heading text-inverse';

export const PrisVartLabel = () => (
  <div className="relative">
    <PrisVart className="w-[60px]" />
    <div className="-mt-0.25 absolute left-2/4 top-2/4 ml-[3px] -translate-x-2/4 -translate-y-2/4 text-center">
      <p className={prisVartTextClasses}>Prisvärt</p>
      <p className={prisVartTextClasses}>från</p>
      <p className={prisVartTextClasses}>hjärtat</p>
    </div>
  </div>
);
